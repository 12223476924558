@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

body {
  color: #ffffff;
  font-family: "Roboto Condensed", sans-serif;

  background-position: top top;
  background-size: cover;
  margin: 0;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  
  background: linear-gradient(
    0deg,
    rgb(21, 209, 84) 0%,
    rgb(0, 255, 136) 50%,
    rgba(26, 197, 83, 0.6) 100%
  );
}

.container {
  width: 100%;
  margin: 15% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}


.main-button {
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
  padding: 6px 12px;
  border: 1px solid #00cc1b;
  line-height: 1.5;
  background-color: #00cc1b;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #fff; /* Adjusted for readability */
}

.main-button:hover {
  opacity: .9;
  box-shadow: none;
}

.main-button:active {
  box-shadow: none;
  background-color: #00cc1b;
  border-color: #00cc1b;
}

.main-button:focus {
  box-shadow: 0 0 0 0.2rem #00cc1b;
}

@font-face {
  font-family: 'les';
  src: url('./assets/fonts/les.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'rawrote';
  src: url('./assets/fonts/rawrote.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GanhThin';
  src: url('./assets/fonts/GanhThin.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'desirableCaligraphy';
  src: url('./assets/fonts/desirableCaligraphy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font-les {
  font-family: 'les';
}
.font-rawrote {
  font-family: 'rawrote';
}
.font-ganh {
  letter-spacing: 1px;
  font-family: 'GanhThin' !important;
}
.font-des {
  font-family: 'desirableCaligraphy';
}

.custom-modal {
  background-color: black !important;
}

.ant-modal-content {
  background-color: black !important;
  border-color: #4ade80;
}

.custom-modal-title {
  font-family: 'GanhThin';
  font-size: 1.8rem;
  color: #4ade80;
}

.custom-ok-button {
  background-color: #4ade80;
  color: black;
  font-family: 'GanhThin';
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.custom-modal-content {
  padding: 1rem;
}
