
 @tailwind base;
 @tailwind components;
 @tailwind utilities;
 /* You can override the default Infima variables here. */
 @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700,800&display=swap');
 
 *{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
 }
 /* You can override the default Infima variables here. */
 :root {
   --ifm-color-primary: #2e8555;
   --ifm-color-primary-dark: #29784c;
   --ifm-color-primary-darker: #277148;
   --ifm-color-primary-darkest: #205d3b;
   --ifm-color-primary-light: #33925d;
   --ifm-color-primary-lighter: #359962;
   --ifm-color-primary-lightest: #3cad6e;
   --ifm-code-font-size: 95%;
   --docusaurus-highlighted-code-line-bg: rgba(0, 0, 0, 0.1);
   --ifm-font-family-base: "Nunito Sans";
   --ifm-menu-link-padding-vertical: var(--ifm-menu-link-padding-horizontal);
 
   --docs-color-border: #dadde1;
 
   --docs-color-text: #000000;
   --docs-color-text-100: #646464;
 
   --docs-color-background: #ffffff;
   --docs-color-background-100: #f8f8f8;
   --docs-color-background-200: #efefef;
   --docs-color-background-300: #dcdcdc;
 }
 
 /* For readability concerns, you should choose a lighter palette in dark mode. */
 [data-theme='dark'] {
   --ifm-color-primary: #25c2a0;
   --ifm-color-primary-dark: #21af90;
   --ifm-color-primary-darker: #1fa588;
   --ifm-color-primary-darkest: #1a8870;
   --ifm-color-primary-light: #29d5b0;
   --ifm-color-primary-lighter: #32d8b4;
   --ifm-color-primary-lightest: #4fddbf;
   --docusaurus-highlighted-code-line-bg: rgba(0, 0, 0, 0.3);
 
   --docs-color-border: #2e2e2e;
 
   --docs-color-text: #ffffff;
   --docs-color-text-100: #b4b4b4;
 
   --docs-color-background: #161616;
   --docs-color-background-100: #1c1c1c;
   --docs-color-background-200: #2a2a2a;
   --docs-color-background-300: #2e2e2e;
 
   --docs-color-code-background: #1e1e1e;
 }
 
 
 .navbar .navbar__items {
   flex: auto;
 }
 
 .menu__list-item {
   font-size: 14px;
 }
 
 
 button:disabled {
   background-color:  #ccc !important;
   color: #ccc;
   cursor: no-drop;
 }
 
 
 .header-github-link:hover {
   opacity: .6
 }
 
 .header-github-link:before {
   background: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12'/%3E%3C/svg%3E") no-repeat;
   content: "";
   display: flex;
   height: 24px;
   width: 24px
 }
 
 
 
 
 .header-telegram-link:hover {
   opacity: .6
 }
 
 .header-telegram-link:before {
   background: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgNTAgNTAiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiPjxwYXRoIGQ9Ik0yNSwyYzEyLjcwMywwLDIzLDEwLjI5NywyMywyM1MzNy43MDMsNDgsMjUsNDhTMiwzNy43MDMsMiwyNVMxMi4yOTcsMiwyNSwyeiBNMzIuOTM0LDM0LjM3NQljMC40MjMtMS4yOTgsMi40MDUtMTQuMjM0LDIuNjUtMTYuNzgzYzAuMDc0LTAuNzcyLTAuMTctMS4yODUtMC42NDgtMS41MTRjLTAuNTc4LTAuMjc4LTEuNDM0LTAuMTM5LTIuNDI3LDAuMjE5CWMtMS4zNjIsMC40OTEtMTguNzc0LDcuODg0LTE5Ljc4LDguMzEyYy0wLjk1NCwwLjQwNS0xLjg1NiwwLjg0Ny0xLjg1NiwxLjQ4N2MwLDAuNDUsMC4yNjcsMC43MDMsMS4wMDMsMC45NjYJYzAuNzY2LDAuMjczLDIuNjk1LDAuODU4LDMuODM0LDEuMTcyYzEuMDk3LDAuMzAzLDIuMzQ2LDAuMDQsMy4wNDYtMC4zOTVjMC43NDItMC40NjEsOS4zMDUtNi4xOTEsOS45Mi02LjY5MwljMC42MTQtMC41MDIsMS4xMDQsMC4xNDEsMC42MDIsMC42NDRjLTAuNTAyLDAuNTAyLTYuMzgsNi4yMDctNy4xNTUsNi45OTdjLTAuOTQxLDAuOTU5LTAuMjczLDEuOTUzLDAuMzU4LDIuMzUxCWMwLjcyMSwwLjQ1NCw1LjkwNiwzLjkzMiw2LjY4Nyw0LjQ5YzAuNzgxLDAuNTU4LDEuNTczLDAuODExLDIuMjk4LDAuODExQzMyLjE5MSwzNi40MzksMzIuNTczLDM1LjQ4NCwzMi45MzQsMzQuMzc1eiIvPjwvc3ZnPg==")  no-repeat;
   content: "";
   display: flex;
   height: 24px;
   width: 24px
 }
 
 .form-live-demo {
   padding: 0px 16px;
   display: flex;
   flex-direction: row;
   align-items: center;
 }
 
 .form-live-demo .field {
   margin-left: 8px;
 }
 
 .content-quicklink-demo pre {
   white-space: pre-wrap;
   word-break: break-all;
 }
 
 
 .form-live-demo button:hover {
   background-color: #277048 !important;
 }
 
 footer img {
   height: 24px;
 }
 
 .footer-part {
   display: flex;
   align-items: center;
   /* justify-content: center; */
 }
 
 .footer-part div {
   margin-right: 10px;
 }
 
 @media (min-width: 996px) {
   .desktop-built-in {
     display: block;
   }
 
   .mobile-built-in {
     display: none;
   }
 }
 
 
 @media (max-width: 996px) {
   .footer__col {
      margin-bottom: 0px;
   }
   .desktop-built-in {
     display: none;
   }
   
   .mobile-built-in {
     display: flex;
     flex-direction: column;
   }
   .navbar__item.downloads {
     display: inline-block;
   }
   .navbar__item.demo {
     display: inline-block;
   }
 }
 
 
 
 /**
  Image caption, use in markdown as:
  ![Image ALT](./path/to/image)
  _my image caption_
  */
  img + em {
   text-align: center;
   display: block;
   margin-top: 1rem;
 }
 
 /**
   Style for a terminal image, markdown usage (add #terminal to end of url):
   ![Image ALT](./path/to/image#terminal)
 */
 img[src$='#terminal'] {
   display: block;
   max-width: 720px;
   margin-left: auto;
   margin-right: auto;
 }
 
 .pad {
   padding: 0 1rem;
 }
 
 .center {
   width: 100%;
   max-width: 1080px;
   margin: 1rem auto;
   min-height: 400px;
 }
 
 .homepage-content {
   padding-bottom: 6rem;
 }
 
 #hero {
   padding: 80px 30px 30px;
   margin-bottom: 4rem;
   border-bottom: 1px solid var(--docs-color-border);
 }
 
 #hero h2 {
   font-size: 36px;
 }
 
 #hero p {
   color: var(--docs-color-text-100);
 }
 
 .section-content {
   display: grid;
   grid-template-columns: repeat(3, minmax(0, 1fr));
   gap: 12px;
 }
 
 .two-cols .section-content {
   grid-template-columns: repeat(2, minmax(0, 1fr));
 }
 
 .has-sub-sections > h3 {
   margin-bottom: 1.5rem;
 }
 
 .has-sub-sections > .section-content {
   grid-template-columns: repeat(1, minmax(0, 1fr));
 }
 .has-sub-sections > .section-content > .homepage-section {
   margin-bottom: 1rem;
   grid-template-columns: repeat(1, minmax(0, 1fr));
 }
 
 .homepage-section {
   margin-bottom: 3rem;
 }
 
 .homepage-section h3 {
   font-weight: 600;
 }
 
 .section-description {
   color: var(--docs-color-text-100);
   margin: 0rem 0 1.25rem 0;
   margin-top: -0.5rem;
 }
 
 .has-sub-sections > .section-content .section-description {
   font-size: 14px;
 }
 
 .homepage-card {
   max-width: 280px;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 10px;
   padding: 0.75rem;
   text-decoration: none;
   color: var(--docs-color-text);
 
   --ifm-link-hover-decoration: none;
   --ifm-link-hover-color: inherit;
   cursor: pointer;
 
   transition-property: background-color, color;
 
   border: 1px solid var(--docs-color-border);
   border-radius: 8px;
 }
 
 .homepage-card:hover {
   background-color: var(--docs-color-background-100);
 }
 
 .icon svg {
   width: auto;
   height: 100%;
 }
 
 .homepage-card .icon {
   width: 48px;
   height: 48px;
   /* background-color: #262626;
   border-radius: 8px; */
 }
 
 .card-content {
   display: flex;
   flex-direction: column;
   gap: 4px;
 }
 
 .card-content .title {
   font-size: 16px;
   letter-spacing: -0.5px;
   font-weight: 600;
 }
 
 .card-content .description {
   font-size: 14px;
   color: var(--docs-color-text-100);
   line-height: 1.5;
 }
 
 @media screen and (max-width: 1160px) {
   /* Hide icons when header UI breaks */
   .pseudo-icon {
     display: none;
   }
 }
 
 @media screen and (max-width: 768px) {
   .section-content {
     grid-template-columns: repeat(1, minmax(0, 1fr));
   }
   .two-cols .section-content {
     grid-template-columns: repeat(1, minmax(0, 1fr));
   }
 }
 @media screen and (max-width: 640px) {
   .form-live-demo {
     padding: 0px;
   }
   .content-quicklink-demo {
     width: 100%;
   }
   .content-vietqr-live-demo .input{
     width: 100%;
   }
   .form-live-demo {
     flex-direction: column;
   }
   .form-live-demo .field {
     margin-left: 0;
   }
   .footer__item {
     display: flex;
     justify-content: space-between;
   }
   .visualize {
     padding: 0;
   }
 }